import React from 'react';
import styled, { keyframes } from 'styled-components';
import Chatboat from '../chatbot/Chatboat';

const starFieldWidth = 2560;
const starFieldHeight = 2560;
const starStartOffset = '600px';

const starOneScrollDuration = '100s';
const starTwoScrollDuration = '125s';
const starThreeScrollDuration = '175s';
const numStarOneStars = 1700;
const numStarTwoStars = 700;
const numStarThreeStars = 200;
const numShootingStars = 10;

const createStars = (n) => {
  let stars = `${Math.random() * starFieldWidth}px ${Math.random() * starFieldHeight}px #FFF`;

  for (let i = 2; i <= n; i++) {
    stars += `, ${Math.random() * starFieldWidth}px ${Math.random() * starFieldHeight}px #FFF`;
  }

  return stars;
};

const animStar = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-${starFieldHeight}px) translateX(-${starFieldWidth}px);
  }
`;

const animShootingStar = keyframes`
  from {
    transform: translateY(0px) translateX(0px) rotate(-45deg);
    opacity: 1;
    height: 5px;
  }
  to {
    transform: translateY(-${starFieldHeight}px) translateX(-${starFieldWidth}px) rotate(-45deg);
    opacity: 1;
    height: 800px;
  }
`;

const StarTemplate = styled.div`
  z-index: 10;
  width: ${(props) => props.starSize};
  height: ${(props) => props.starSize};
  border-radius: 50%;
  background: transparent;
  box-shadow: ${(props) => createStars(props.numStars)};
  animation: ${animStar} ${(props) => props.scrollSpeed} linear infinite;

  &::after {
    content: " ";
    top: -${starStartOffset};
    width: ${(props) => props.starSize};
    height: ${(props) => props.starSize};
    border-radius: 50%;
    position: absolute;
    background: transparent;
    box-shadow: ${(props) => createStars(props.numStars)};
  }
`;

const ShootingStarTemplate = styled.div`
  z-index: 10;
  width: ${(props) => props.starSize};
  height: ${(props) => `calc(${props.starSize})`};
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1));
  animation: ${animShootingStar} ${(props) => props.speed} linear infinite;
`;

const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #020107 0%, #201b46 100%);
`;


const Sky = () => (
  <Container>
    <div>
  <StarTemplate  starSize="1px" numStars={numStarOneStars} scrollSpeed={starOneScrollDuration} />
    <StarTemplate starSize="2px" numStars={numStarTwoStars} scrollSpeed={starTwoScrollDuration} />
    <StarTemplate starSize="3px" numStars={numStarThreeStars} scrollSpeed={starThreeScrollDuration} />
    <ShootingStarTemplate starSize="5px" numStars={numShootingStars} speed="10s" />
    <Chatboat/> 
    </div> 

  </Container>
);

const Homepage = () => (

    <Sky />
);

export default Homepage;
