import React, { useState } from 'react';
import { FullPageChat } from 'flowise-embed-react';
import ROBOT from '../../assets/images/robot.png';
import GIFT from '../../assets/images/gift.gif';

function Chatboat() {
  return (
    <div className='relative z-50 h-screen max-w-1440 w-full mx-auto '>
      <div className='flex flex-col lg:flex-row h-full'>
        <div className='hidden xl:w-[23%] lg:w-[27%] lg:flex lg:flex-col relative'>
          <div className='h-[500px] rounded-bl-[70%] rounded-br-[70%] border pt-[5vh] border-l-gray-700 border-r-gray-700 border-b-gray-700 border-l-gray-700 border-t-[#030208] relative'>
            <div className='flex justify-center'>
              <img src={ROBOT} alt='robot' id='scetch-board' className='w-[230px]' />
            </div>
            <p className='text-[#cdcfe3] text-start xl:px-[15px] px-3 xl:text-[16px] lg:text-[13px] rounded-[5px] 2xl:mx-3'>
              Make your gifts unforgettable with personalized suggestions from me!
            </p>
            <div className='w-full mx-auto xl:max-w-[80%] lg:max-w-[70%] border-t-2 border-t-[#cdcfe3]'>
              <h1 className='text-[#c5d5ed] text-[18px] text-center'>Demo Prompt</h1>
              <p className='text-[13px] font-semibold text-[#a19ec9] text-center mt-2'>
                Age:30, Relationship:Wife, Occasion:Birthday, Interests:Beauty Products, jewellery, Min budget:5000, Max budget:8000.
              </p>
            </div>
            <div className='absolute inset-x-0 top-[90%] flex justify-center'>
              <img src={GIFT} alt='Example GIF' className='w-[90px]' />
            </div>
          </div>
        </div>
        <div className='relative w-full xl:w-[54%] lg:w-[50%] flex justify-center items-center h-full'>
          <div className=' h-full w-full box-border flex items-center justify-center lg:mx-0 mx-3' style={{ overflow: 'auto' }}>
            <FullPageChat
              chatflowid='cfc346a7-60bd-4256-97c1-9425c878d497'
              apiHost='https://flowiseai-railway-production-49ab.up.railway.app'
              className=' h-[100%] overflow-hidden w-full'
              theme={{
                chatWindow: {
                  showTitle: true,
                  title: 'AI Gifting',
                  titleAvatarSrc: 'https://img.icons8.com/ios11/200w/FFFFFF/brain.png',
                  showAgentMessages: true,
                  welcomeMessage: 'Hello! This is custom welcome message',
                  errorMessage: 'This is a custom error message',
                  backgroundColor: '#bfc9d7',
                  width: '100%',
                  border: '3px solid green',
                  poweredByTextColor: '#303235',
                  botMessage: {
                    backgroundColor: '#f7f8ff',
                    textColor: '#303235',
                    showAvatar: true,
                    avatarSrc: 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/parroticon.png',
                  },
                  userMessage: {
                    backgroundColor: '#3B81F6',
                    textColor: '#ffffff',
                    showAvatar: true,
                    avatarSrc: 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
                  },
                  textInput: {
                    placeholder: 'Type your question',
                    backgroundColor: '#ffffff',
                    color: '#303235',
                    sendButtonColor: '#3B81F6',
                    autoFocus: true,
                    sendMessageSound: true,
                    receiveMessageSound: true,
                    fontSize: 12,
                    height: '70px',
                  },
                  feedback: {
                    color: '#303235',
                    height: '100px',
                  },
                  footer: {
                    textColor: '#303235',
                    text: 'Powered by',
                    company: 'Accuwisetech',
                    height: '200px',
                    companyLink: 'https://accuwisetech.com/',
                  },
                },
              }}
            />
          </div>
        </div>
        <div className='hidden lg:w-[23%] lg:flex lg:flex-col lg:justify-end relative'>
          <div className='absolute bottom-0 w-full'>
            <div className='flex justify-center mb-[-40px]'>
              <img src={GIFT} alt='Example GIF' className='w-[90px]' />
            </div>
            <div className='h-[500px] rounded-tl-[70%] rounded-tr-[70%] border flex flex-col justify-end border-gray-700'>
              <p className='text-[#cdcfe3] text-center 2xl:h-[250px] xl:h-[200px] h-[200px] pt-3 px-[30px] text-[16px] rounded-[5px] 2xl:mx-5'>
                Share a few details and get personalized gift ideas for your loved ones.
              </p>
              <div className='flex justify-center'>
                <img src={ROBOT} alt='robot' id='scetch-board' className='w-[250px]' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chatboat;
