import './App.css';
import Homepage from './components/homepage/Homepage';

function App() {
  
  return (
 <div style={{ height:"100vh",overflow:'hidden'}}>
  <Homepage/>
 </div>
  );
}

export default App;
